import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../components/seo";
import BaseLayout from "../../components/layout"
import HorizontalRule from "../../components/horizontal-rule";
import TextBlock from "../../components/text-block";
import BreadcrumbTrail from "../../components/breadcrumb-trail";


export default class UpcomingWorkPage extends React.Component  {
  render() {

    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Upcoming Work',
        }
      ]
    ]

    return (
      <BaseLayout>
        <Seo title="Upcoming Work" desc="Bibliography of all written work by Zack Thoutt." pathname={"/writing/bibliography/"} breadcrumbList={breadcrumbList}></Seo>
        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Upcoming Work</h1>
          </div>

          <div className="mx-auto layout-reading-w w-full article-layout mt-28">
            <TextBlock text="Here is a look at my upcoming work. Sometimes I find unexpected inspiration and deviate from my plans, but in general, this is what I'll spend my time writing in the near future."></TextBlock>
            <ol className="mt-8">
              <li><b className="italic"><Link className="text-link" to="/writing/library/wally-and-the-holiday-jailbreak">Wally and the Holiday Jailbreak</Link>:</b> I published this holiday comedy on December 12, 2022, but am working with a new editor to add more literary elements and double the word count to make it a full-length novel. I hope to publish a second edition in Spring 2023 and then jump straight into a sequel.</li>
              <li><b className="italic">The War After War:</b> I have a novel in the works tentatively titled, <i>The War After War</i>, that is a blend of historical fiction, thriller, and drama. I have a detailed outline finished and would love to publish this by the end of 2023.</li>
              <li><b>Short Stories:</b> I enjoy writing short stories and plan to publish a handful per year. I use short stories as a way to explore genres and ideas, so one email you might receive a thriller, the following a fantasy or sci-fi, and the next a historical fiction. Someday I might expand my best short stories into full novels.</li>
            </ol>
            <Link to="/writing/latest/"><button className="btn btn-wide btn-gold mt-12">Read Latest Stories</button></Link>
          </div>

        </div>
      </BaseLayout>
    )
  }
}


export const pageQuery = graphql`
  query UpcomingWork {
    site {
      siteMetadata {
        title
      }
    }
  }
`